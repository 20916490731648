import React, { useState } from "react";
import HeroImage from "../../assets/img/portfolio/Rahul&Namrata/Rahul&Namrata.jpg";

import gallery from "../../assets/img/bg/weiding-bg.webp";

const RahulNamrata = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // Define your image paths here
  const images = {
    portfolio1: require("../../assets/img/portfolio/Rahul&Namrata/1.webp"),
    portfolio2: require("../../assets/img/portfolio/Rahul&Namrata/2.webp"),
    portfolio3: require("../../assets/img/portfolio/Rahul&Namrata/3.webp"),
    portfolio4: require("../../assets/img/portfolio/Rahul&Namrata/4.webp"),
    portfolio5: require("../../assets/img/portfolio/Rahul&Namrata/5.webp"),
    portfolio6: require("../../assets/img/portfolio/Rahul&Namrata/6.webp"),
    portfolio7: require("../../assets/img/portfolio/Rahul&Namrata/7.webp"),
    portfolio8: require("../../assets/img/portfolio/Rahul&Namrata/8.webp"),
    portfolio9: require("../../assets/img/portfolio/Rahul&Namrata/9.webp"),
    portfolio10: require("../../assets/img/portfolio/Rahul&Namrata/10.webp"),
    portfolio11: require("../../assets/img/portfolio/Rahul&Namrata/11.webp"),
    portfolio12: require("../../assets/img/portfolio/Rahul&Namrata/12.webp"),
    portfolio13: require("../../assets/img/portfolio/Rahul&Namrata/13.webp"),
    portfolio14: require("../../assets/img/portfolio/Rahul&Namrata/14.webp"),
    portfolio15: require("../../assets/img/portfolio/Rahul&Namrata/15.webp"),
    portfolio16: require("../../assets/img/portfolio/Rahul&Namrata/16.webp"),
    portfolio17: require("../../assets/img/portfolio/Rahul&Namrata/17.webp"),
    portfolio18: require("../../assets/img/portfolio/Rahul&Namrata/18.webp"),
    portfolio19: require("../../assets/img/portfolio/Rahul&Namrata/19.webp"),
    portfolio20: require("../../assets/img/portfolio/Rahul&Namrata/20.webp"),
    portfolio21: require("../../assets/img/portfolio/Rahul&Namrata/21.webp"),
    portfolio22: require("../../assets/img/portfolio/Rahul&Namrata/22.webp"),
    portfolio23: require("../../assets/img/portfolio/Rahul&Namrata/23.webp"),
    portfolio24: require("../../assets/img/portfolio/Rahul&Namrata/24.webp"),
    portfolio25: require("../../assets/img/portfolio/Rahul&Namrata/25.webp"),
    portfolio26: require("../../assets/img/portfolio/Rahul&Namrata/26.webp"),
    portfolio27: require("../../assets/img/portfolio/Rahul&Namrata/27.webp"),
    portfolio28: require("../../assets/img/portfolio/Rahul&Namrata/28.webp"),
    portfolio29: require("../../assets/img/portfolio/Rahul&Namrata/29.webp"),
    portfolio30: require("../../assets/img/portfolio/Rahul&Namrata/30.webp"),
    portfolio31: require("../../assets/img/portfolio/Rahul&Namrata/31.webp"),
    portfolio32: require("../../assets/img/portfolio/Rahul&Namrata/32.webp"),
    portfolio33: require("../../assets/img/portfolio/Rahul&Namrata/33.webp"),
    portfolio34: require("../../assets/img/portfolio/Rahul&Namrata/34.webp"),
    portfolio35: require("../../assets/img/portfolio/Rahul&Namrata/35.webp"),
    portfolio36: require("../../assets/img/portfolio/Rahul&Namrata/36.webp"),
    portfolio37: require("../../assets/img/portfolio/Rahul&Namrata/37.webp"),
    portfolio38: require("../../assets/img/portfolio/Rahul&Namrata/38.webp"),
    portfolio39: require("../../assets/img/portfolio/Rahul&Namrata/39.webp"),
  };

  // Function to handle image click: opens the modal with the selected image
  const openModal = (imageKey) => {
    setSelectedImage(images[imageKey]);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const containerStyle = {
    background: `url(${HeroImage}) center/cover no-repeat`,
    backgroundColor: "#1C242970", // This sets a semi-transparent overlay color
    height: "60vh",
  };

  return (
    <div>
      <div
        style={containerStyle}
        className="flex  items-center justify-center relative -z-[900]"
      >
        <div className="absolute inset-0 bg-[#0000007f] z-0"></div>
        <h2 className="text-white text-center text-[60px] z-0">
          {" "}
          Rahul & Namrata
        </h2>
      </div>

      <div class="flex flex-col lg:flex-row justify-center gap-10 lg:px-20 px-2 py-10">
        <div className="lg:w-[500px]">
          <div class="mb-35">
            <h4 class="lv-portfolio-details-title-1-4 text-[#000066]">
              Rahul & Namrata
            </h4>

            <span class="">December 2022, Radisson Blu, Mumbai</span>
          </div>
          <div class="lv-portfolio-details-content-1-4 mb-30">
            <h2>A grand wedding in the heart of Mumbai</h2>
            <p class="mb-25">
              Rahul and Namrata's wedding was a grand affair, held in the heart
              of bustling Mumbai. Set against the backdrop of the city's vibrant
              energy, their union was a celebration of love, family, and
              tradition.
            </p>
            <p>
              The venue was adorned with opulent decorations, setting the stage
              for a lavish ceremony befitting the occasion. Friends and family
              gathered from near and far, adding to the jubilant atmosphere with
              their warmth and excitement.
            </p>
            <p>
              Amidst the glitz and glamour, Rahul and Namrata exchanged vows,
              promising to stand by each other through thick and thin. Their
              love shone brightly, illuminating the room with its pure radiance.
            </p>
            <p>
              From the sumptuous feast to the lively music and dance, every
              moment of the wedding was filled with joy and merriment. Guests
              reveled in the festivities, cherishing the opportunity to witness
              the beginning of a beautiful new chapter in Rahul and Namrata's
              lives.
            </p>
          </div>
        </div>
        <div className="lg:w-[600px]">
          {/* Mapping other images, assuming similar behavior for simplicity */}
          <div className="py-3">
            <img
              className=""
              src={require("../../assets/img/portfolio/Rahul&Namrata/17.webp")}
            />
          </div>
          <div className="flex flex-col lg:flex-row gap-3">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio1"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio2"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio3"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio4"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio5"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio6"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio7"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio8"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-no-repeat  bg-right-top"
              style={{
                backgroundImage: `url(${images["portfolio9"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio10"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio11"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio12"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio13"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio14"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio15"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio16"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio17"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio18"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio19"]})`,
              }}
            ></div>
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-top-center"
              style={{
                backgroundImage: `url(${images["portfolio20"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio21"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio22"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio23"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio23"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio24"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio25"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio26"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio27"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio28"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio29"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio30"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio31"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio32"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio33"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio34"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio35"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio36"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio37"]})`,
              }}
            ></div>
          </div>
          <div className="flex gap-3 py-3 flex-col lg:flex-row">
            <div
              className="lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio38"]})`,
              }}
            ></div>
            <div
              className=" lg:w-[400px] h-[300px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${images["portfolio39"]})`,
              }}
            ></div>
          </div>

          {/* Modal for displaying the clicked image */}
          {isModalOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
              onClick={closeModal}
            >
              <img
                src={selectedImage}
                alt="Modal"
                style={{ maxWidth: "90%", maxHeight: "90%" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RahulNamrata;
